input[tablefilter='true']::-webkit-calendar-picker-indicator {
  display: none;
}

input[tablefilter='true']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[tablefilter='true']::-webkit-clear-button {
  display: none;
}
